import { graphql } from "babel-plugin-relay/macro";
import { commitMutation } from "react-relay";

import environment from "../../Environment";

import {
  UpdateProjectMonth_Mutation,
  type ProjectUpdateInputType,
} from "./__generated__/UpdateProjectMonth_Mutation.graphql";

const mutation = graphql`
  mutation UpdateProjectMonth_Mutation($input: ProjectUpdateInput!) {
    updateProject(input: $input) {
      project {
        ...ProjectsPage_project @relay(mask: false)
      }
    }
  }
`;

export default ({ id, month, index }: ProjectUpdateInputType) => {
  const variables = {
    input: {
      project: {
        id,
        month,
        index
      },
    },
  };

  commitMutation<UpdateProjectMonth_Mutation>(environment, {
    mutation,
    variables,
  });
};
