import { graphql } from "babel-plugin-relay/macro";
import { createFragmentContainer } from "react-relay";

import { permissions } from "../../services/permissions";

import Details from "@/pagesExample/apps/tasks/Details";
import { useAssignment } from "@/pages_old/Assign/Assignment";
import { ParentLink } from "./Tasks";
import updateTask from "./UpdateTask";
import { useUpdateModal } from '@/pages_old/Tasks/UpdateModal';

import { ShowPageTask_task$data as TaskType } from "./__generated__/ShowPageTask_task.graphql";

export interface PropsType {
  task: TaskType;
}

const TaskShowPage = ({ task }: PropsType) => {
  const {
    // assignment: { create: assignmentCreate },
    task: { update: unlocked },
  } = permissions();

  const { dueDate, id, name, complete, assignees, parent: owner } = task;

  const markComplete = () => {
    if (unlocked) {
      updateTask({ id, complete: !complete });
    }
  };

  const updateDueDate = (date: Date) => {
    if (unlocked) {
      updateTask({ id, dueDate: date });
    }
  };

  const { toggleShow, Modal: AssignmentModal } = useAssignment({
    assignable: { id, type: "task" },
    assignees,
  });

  const { Modal: UpdateModal, toggleShow: toggleUpdateShow } = useUpdateModal({
    task: {
      id,
      description: name || '',
    }
  })

  const dropDown = [
    {
      label: complete ? "Mark Incomplete" : "Mark Complete",
      className: complete ? "mdi-close" : "mdi-check",
      onClick: markComplete,
    },
    {
      label: 'Edit',
      className: "mdi-pencil",
      onClick: toggleUpdateShow,
    },
    {
      label: "Add assignees",
      className: "mdi-plus-circle-outline",
      onClick: toggleShow,
    },
  ];

  return (
    <>
      <UpdateModal />
      <AssignmentModal />
      <Details
        header={<ParentLink className="d-block" owner={owner} />}
        dropdowns={dropDown}
        breadcrumbs={[{ name: "Tasks", href: "/tasks" }]}
        title="Task Details"
        commentable={{
          id,
          type: "task",
        }}
        task={{
          id,
          title: name,
          users: assignees,
          dueDate: dueDate,
          priority: {
            name: complete ? "Complete" : "Incomplete",
            key: complete ? "Low" : "High",
          },
        }}
        comments={task}
        updateDate={updateDueDate}
      />
    </>
  );
};

export default createFragmentContainer(TaskShowPage, {
  task: graphql`
    fragment ShowPageTask_task on Task {
      assignees {
        ...Avatars_users
        ...Assignment_Assignees
      }
      id
      name
      complete
      dueDate
      ... on CommentableInterface {
        ...CommentFeed_comments
      }
      parent {
        ... on TaskableInterface {
          id
          name
          __typename
        }
      }
    }
  `,
});
