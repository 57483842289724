import { graphql } from "babel-plugin-relay/macro";
import { commitMutation } from "react-relay";
import environment from "../../Environment";
import { RemoveTask_Mutation } from "./__generated__/RemoveTask_Mutation.graphql";

const mutation = graphql`
  mutation RemoveTask_Mutation($input: TaskDeleteInput!, $connections: [ID!]!) {
    deleteTask(input: $input) {
      deletedTaskIds @deleteEdge(connections: $connections)
    }
  }
`;

const removeTask = (id: string, connectionId: string) => {
  if (window.confirm("Are you sure you want to delete this task?")) {
    const variables = {
      input: {
        ids: [id],
      },
      connections: [connectionId],
    };

    commitMutation<RemoveTask_Mutation>(environment, {
      mutation,
      variables,
    });
  }
};

export default removeTask;
