import { type Assignables } from "@/pages_old/Assign/Assignment";
import { map } from "lodash";
import { Col, Row } from "react-bootstrap";
import { ListTaskItem } from "../types";
import TaskSection, { type CentralColumnType } from "./Section";
export { type ListTaskItem } from "../types";

interface PropsType {
  children?: (item: ListTaskItem) => React.ReactNode;
  collapsable?: boolean;
  sections?: {
    id: string;
    title?: string;
    tasks: ListTaskItem[];
  }[];
  type?: Assignables;
  onUpdateDate?: (date: Date | null, { id }: ListTaskItem) => void;
  showTime?: boolean;
  onCheck?: (task: ListTaskItem) => void;
  showParent?: boolean;
  short?: boolean;
  CentralColumn?: CentralColumnType
  ellipse?: boolean
}

const ListTask = ({
  collapsable = true,
  children,
  sections = [],
  type,
  onUpdateDate,
  showTime = false,
  showParent = false,
  onCheck,
  short = false,
  CentralColumn,
  ellipse
}: PropsType) => {
  // const [selectedTask, selectTask] = useState<ListTaskItem | null>();

  const selectedTask = false;
  return (
    <Row>
      <Col xxl={selectedTask ? 8 : 12}>
        {map(sections, (section, i) => {
          return (
            <div className="mt-4" key={section.id}>
              <TaskSection
                ellipse={ellipse}
                CentralColumn={CentralColumn}
                showParent={showParent}
                showTime={showTime}
                title={section.title}
                tasks={section.tasks}
                collapsable={collapsable}
                onDateUpdate={onUpdateDate}
                type={type}
                onCheck={onCheck}
                short={short}
              >
                {children}
              </TaskSection>
            </div>
          );
        })}
      </Col>
    </Row>
  );
};

export { ListTask };
