import { useCallback } from 'react';
import { graphql } from "babel-plugin-relay/macro";
import { compact, map, some } from "lodash";
import { useState } from "react";
import { Button, Modal, Tab } from "react-bootstrap";
import { useFragment, useLazyLoadQuery } from "react-relay";
import assign from "./Assign";
import unAssign from "./UnAssign";

import Avatar from "../Users/Avatar";
import { Assignables } from "./__generated__/AssignMutation.graphql";
import { AssignmentQuery as AssignmentQueryType } from "./__generated__/AssignmentQuery.graphql";
import { Assignment_Assignees$key as AssignmentsType } from "./__generated__/Assignment_Assignees.graphql";
export { type Assignables } from "./__generated__/AssignMutation.graphql";
export { type Assignment_Assignees$key as AssignmentsType } from "./__generated__/Assignment_Assignees.graphql";

const { loadStore } = require("../../redux/store/localStorage");

const getCurrentClub = () => {
  try {
    return loadStore().currentClub || {};
  } catch (err) {
    return {};
  }
};

export interface AssignableInputType {
  id: string;
  type: Assignables;
}

interface PropsType {
  verb?: string;
  title?: string;
  assignable: AssignableInputType;
  assignees: AssignmentsType | null | undefined;
  children?: (toggleShow: () => void) => JSX.Element;
}

const AssignmentQuery = graphql`
  query AssignmentQuery($id: ID!) {
    club(id: $id) {
      members {
        edges {
          node {
            id
            name
            ...Avatar_user
          }
        }
      }
    }
  }
`;

const AssigneesFragment = graphql`
  fragment Assignment_Assignees on UserConnection {
    __id
    edges {
      node {
        id
        name
      }
    }
  }
`;
//
// type MemberType = Exclude<
//   Exclude<
//     Exclude<
//       Exclude<
//         Exclude<
//           Exclude<AssignmentQueryType["response"], undefined>["club"],
//           null | undefined
//         >["members"],
//         null | undefined
//       >["edges"],
//       null | undefined
//     >[number],
//     null | undefined
//   >['node'],
//   null | undefined
// >;

const modalBodyStyle = {
  minHeight: "22rem",
};

const AssignModal = ({
  title,
  verb,
  show,
  toggleShow,
  assignable,
  assignees: assigneesKey,
}: {
  title: string;
  verb: string;
  show: boolean;
  toggleShow: () => void;
  assignees: AssignmentsType | null | undefined;
} & PropsType) => {
  const { currentClub } = getCurrentClub();
  const clubId = currentClub?.id;

  const { club } = useLazyLoadQuery<AssignmentQueryType>(AssignmentQuery, {
    id: clubId,
  });
  if (!club) return <>nc</>;
  const { members } = club;

  const assignees = useFragment<AssignmentsType>(
    AssigneesFragment,
    assigneesKey,
  );

  const connections: string[] = compact([assignees?.__id]);
  return (
    <Modal
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={show}
      onHide={toggleShow}
    >
      <Tab.Container defaultActiveKey="signIn" data-no-dnd="true">
        <Modal.Header closeButton>{title}</Modal.Header>
        <Modal.Body style={modalBodyStyle}>
          {map(compact(members?.edges || []), (edge) => {
            const { node: member } = edge
            if(!member) { return <></> }

            const alreadyAssigned = some(
              assignees?.edges || [],
              (edge) => edge?.node?.id == member?.id,
            );
            const assignmentFunction = alreadyAssigned ? unAssign : assign;

            return (
              <a
                key={member.id}
                onClick={() =>
                  assignmentFunction({
                    assignableId: assignable.id,
                    assignableType: assignable.type,
                    assigneeId: member?.id,
                    connections,
                  })
                }
              >
                <div
                  className={`d-flex align-items-center mt-1 ms-1 ${alreadyAssigned ? "bg-light" : ""}`}
                >
                  <Avatar user={member} className='ms-0' hover={false} />
                  <span className="ps-2">{member.name}</span>
                  {alreadyAssigned && <i className="bi bi-check"></i>}
                </div>
              </a>
            );
          })}
        </Modal.Body>
      </Tab.Container>
    </Modal>
  );
};

const defaultChildren = (toggleShow: () => void) => {
  return (
    <Button onClick={toggleShow}>
      <i className="bi bi-plus"></i>
    </Button>
  );
};

const Assign = ({
  verb = "assigned",
  title = "Assign",
  assignable,
  assignees,
  children = defaultChildren,
}: PropsType) => {
  const [show, updateShow] = useState(false);
  const toggleShow = () => updateShow(!show);

  return (
    <>
      {children(toggleShow)}
      {show && (
        <AssignModal
          {...{ show, toggleShow, assignable, assignees, title, verb }}
        />
      )}
    </>
  );
};

export default Assign;

export const useAssignment = ({
  verb = "assigned",
  title = "Assign",
  assignable,
  assignees,
}: Exclude<PropsType, "children">) => {
  const [show, updateShow] = useState(false);
  const toggleShow = () => updateShow(!show);

  const Modal = useCallback(() => (
    <AssignModal
      {...{ show, toggleShow, assignable, assignees, title, verb }}
    />
  ), [title, verb, show]);

  return { toggleShow, Modal };
};
