import { DropDown, type MenuItem as DropDownType } from "@/components";
import { useToggle } from "@/hooks";
import { useAssignment, type Assignables } from "@/pages_old/Assign/Assignment";
import { type Taskables } from "@/pages_old/Tasks/CreateTaskForm";
import { ParentLink } from "@/pages_old/Tasks/Tasks";
import Avatars from "@/pages_old/Users/Avatars";
import classNames from "classnames";
import Moment from "moment";
import { forwardRef, useState } from "react";
import { Card, Col, Collapse, Row } from "react-bootstrap";
import DatePicker from "react-datepicker";
import LinesEllipsis from "react-lines-ellipsis";
import { Link } from "react-router-dom";
import { ListTaskItem } from "../types";

export type CentralColumnType = (p: {
  short: boolean;
  task: ListTaskItem;
  assignable?: { id: string; type?: Assignables };
}) => JSX.Element;
const DefaultCentralColumn = ({
  short,
  task,
  assignable,
}: {
  short: boolean;
  task: ListTaskItem;
  assignable?:
    | { id: string; type: Assignables }
    | { id: string; type: undefined };
}) => {
  if (short && task.users && assignable?.type) {
    return <Avatars users={task.users} assignable={assignable} />;
  }

  return <></>;
};

const OptionalEllipse = ({ content, ellipse }: { content: string, ellipse: boolean }) => {
  if(ellipse) {
    return(
      <LinesEllipsis
      text={content}
      maxLine="1"
      ellipsis="..."
      trimRight
      basedOn="letters"
    />
    )
  }
  return(content)
};

const Task = ({
  task,
  selectTask,
  onCheck,
  onDateUpdate,
  showTime = false,
  showParent = false,
  type,
  children,
  short = false,
  CentralColumn = DefaultCentralColumn,
  ellipse = true
}: {
  ellipse?: boolean
  task: ListTaskItem;
  selectTask?: (task: ListTaskItem) => void;
  onCheck?: (task: ListTaskItem) => void;
  onDateUpdate?: (d: Date | null, t: ListTaskItem) => void;
  showTime?: boolean;
  showParent?: boolean;
  type?: Assignables;
  children?: (item: ListTaskItem) => React.ReactNode;
  short?: boolean;
  CentralColumn?: CentralColumnType;
}) => {
  const { complete, id } = task;
  const [dueDate, setStartDate] = useState<Date | null>(
    Moment(task.dueDate).toDate(),
  );

  const ExampleCustomInput = forwardRef<HTMLInputElement, {}>(
    ({ value, onClick }: any, ref) => (
      <span onClick={onClick}>
        <i className="uil uil-schedule font-16 me-1" ref={ref} />{" "}
        {Moment(task.dueDate).format(showTime ? "D MMM h:mm a" : "D MMM")}
      </span>
    ),
  );

  var dropdowns: DropDownType[] = [];
  var AssignmentModal = () => <></>;

  if (type) {
    const assignment = useAssignment({
      assignable: { id, type },
      assignees: task.users,
    });
    AssignmentModal = assignment.Modal;

    dropdowns = [
      {
        label: "Add assignees",
        icon: "mdi-plus-circle-outline",
        onClick: assignment.toggleShow,
      },
    ];
  }

  return (
    <Row className="justify-content-sm-between mt-2">
      <AssignmentModal />
      <Col className="mb-2 mb-sm-0">
        <div className="form-check">
          {onCheck && (
            <input
              type="checkbox"
              className="form-check-input"
              id={`task-${task.id}`}
              defaultChecked={complete}
              onChange={() => onCheck(task)}
            />
          )}
          <span className="form-check-label">
            {task.link ? (
              <a href={task.link}>
                <OptionalEllipse content={task.title || ""}  ellipse={ellipse} />
              </a>
            ) : (
              <OptionalEllipse content={task.title || ""} ellipse={ellipse}/>
            )}
          </span>
        </div>
      </Col>
      {showParent && task.owner && (
        <Col className="d-none d-md-block">
          <ParentLink owner={task.owner} />
        </Col>
      )}
      <Col>
        <CentralColumn
          short={short}
          task={task}
          assignable={type ? { id, type } : undefined}
        />
      </Col>
      <Col>
        <ul className="list-inline font-13 text-end">
          {onDateUpdate && (
            <li className="list-inline-item d-none d-md-inline">
              <DatePicker
                selected={dueDate}
                onChange={(newDate) => {
                  onDateUpdate && onDateUpdate(newDate, task);
                  setStartDate(newDate);
                }}
                showTimeSelect={showTime}
                customInput={<ExampleCustomInput />}
              />
            </li>
          )}
          {!short && task.comments && (
            <li className="list-inline-item ms-1">
              <i className="uil uil-comment-message font-16 me-1"></i>
              {task.comments.totalCount}
            </li>
          )}
          {!short && task.tasks && (
            <li className="list-inline-item ms-1">
              <i className="uil ri-list-check-3 font-16 me-1"></i>
              {task.tasks.totalCount}
            </li>
          )}
          {task.hidden !== undefined && (
            <li className="list-inline-item ms-1">
              <i
                className={`uil uil-file${task.hidden ? "-slash" : ""} font-16 me-1`}
              ></i>
            </li>
          )}

          {task.link && (
            <li className="list-inline-item ms-1">
              <a href={task.link} style={{ color: "inherit" }}>
                <i className="uil uil-external-link-alt font-16 me-1"></i>
              </a>
            </li>
          )}
          {!short && type && (
            <li className="list-inline-item ms-1">
              <DropDown menuItems={dropdowns} className="">
                <i className="mdi mdi-dots-vertical"></i>
              </DropDown>
            </li>
          )}

          <li className="list-inline-item ms-2">
            {task.priority && (
              <span
                className={classNames(
                  "badge",
                  {
                    "badge-danger-lighten": task.priority.key === "High",
                    "badge-info-lighten": task.priority.key === "Medium",
                    "badge-success-lighten": task.priority.key === "Low",
                  },
                  "p-1",
                )}
              >
                {task.priority.name}
              </span>
            )}
          </li>
          {children && children(task)}
        </ul>
      </Col>
    </Row>
  );
};

type TaskSectionProps = {
  CentralColumn?: CentralColumnType;
  ellipse?: boolean;
  title?: string;
  tasks: Array<ListTaskItem>;
  selectTask?: (task: ListTaskItem) => void;
  collapsable?: boolean;
  onCheck?: (task: ListTaskItem) => void;
  onDateUpdate?: (d: Date | null, t: ListTaskItem) => void;
  showTime?: boolean;
  showParent?: boolean;
  type?: Assignables;
  children?: (item: ListTaskItem) => React.ReactNode;
  short?: boolean;
  taskable?: {
    id: string;
    type: Taskables;
  };
};

const Tasks = ({
  type,
  title,
  tasks,
  collapsable,
  onCheck,
  onDateUpdate,
  selectTask,
  children,
  short = false,
  showTime = false,
  showParent = false,
  CentralColumn,
  ellipse
}: TaskSectionProps) => (
  <Card className="mb-0">
    <Card.Body className="pb-1 pt-2">
      {tasks.map((task, index) => (
        <Task
          ellipse={ellipse}
          CentralColumn={CentralColumn}
          showParent={showParent}
          showTime={showTime}
          task={task}
          key={task.id}
          onCheck={onCheck}
          onDateUpdate={onDateUpdate}
          selectTask={selectTask}
          type={type}
          short={short}
        >
          {children}
        </Task>
      ))}
    </Card.Body>
  </Card>
);

const TaskSection = (props: TaskSectionProps) => {
  const { title, tasks, collapsable } = props;
  const [isCollapsed, toggleCollapse] = useToggle(true);

  if (!collapsable) {
    return <Tasks {...props} />;
  }

  return (
    <>
      <h5 className="m-0 pb-2">
        <Link className="text-dark" to="" onClick={toggleCollapse}>
          <i
            className={classNames(
              "uil",
              {
                "uil-angle-down": isCollapsed,
                "uil-angle-right": !isCollapsed,
              },
              "font-18",
            )}
          ></i>
          {title} <span className="text-muted">({tasks.length})</span>
        </Link>
      </h5>
      <Collapse in={isCollapsed}>
        <Tasks {...props} />
      </Collapse>
    </>
  );
};

export default TaskSection;
