import { graphql } from "babel-plugin-relay/macro";
import { PageBreadcrumb } from "@/components";
import { Card, Nav, Tab } from "react-bootstrap";
import { useLazyLoadQuery } from "react-relay";

import { SettingsForm, DisplaySettings } from "../Settings";

import { SettingsPage_Query } from "./__generated__/SettingsPage_Query.graphql";

const query = graphql`
  query SettingsPage_Query {
    viewer {
      currentUser {
        id
        name
        partyPooper
        optInEmailReminders
      }
    }
  }
`;

export default () => {
  const {
    viewer: { currentUser: user },
  } = useLazyLoadQuery<SettingsPage_Query>(query, {});
  if (!user) return <></>;

  return (
    <>
      <PageBreadcrumb title="Settings" />

      <Tab.Container defaultActiveKey="accountSettings">
        <Card>
          <Card.Body>
            <Nav
              as="ul"
              variant="pills"
              className="nav nav-pills bg-nav-pills nav-justified mb-3"
            >
              <Nav.Item as="li" className="nav-item">
                <Nav.Link href="" eventKey="accountSettings" className=" rounded-0">
                  Account Settings
                </Nav.Link>
              </Nav.Item>
              <Nav.Item as="li" className="nav-item">
                <Nav.Link
                  href=""
                  eventKey="displaySettings"
                  className=" rounded-0"
                >
                  Display Settings
                </Nav.Link>
              </Nav.Item>
            </Nav>

            <Tab.Content>
              <Tab.Pane eventKey="accountSettings">
                <SettingsForm user={user} />
              </Tab.Pane>
              <Tab.Pane eventKey="displaySettings">
                <DisplaySettings />
              </Tab.Pane>
            </Tab.Content>
          </Card.Body>
        </Card>
      </Tab.Container>

    </>
  );
};
