import { ReactNode } from "react";
import { Breadcrumb, Col, Row } from "react-bootstrap";

export type CrumbType = {
  name: string;
  href?: string;
}

type PageTitleProps = {
  title: ReactNode;
  subName?: CrumbType[];
  children?: ReactNode;
  className?: string;
};

const PageBreadcrumb = ({ subName = [], title, children }: PageTitleProps) => {
  if (typeof subName === "string") {
    subName = [subName];
  }
  return (
    <>
      <Row>
        <Col>
          <div className="page-title-box">
            {children ?? null}
            {subName.length > 0 && (
              <div className="page-title-right">
                <Breadcrumb listProps={{ className: "m-0" }}>
                  {subName.map(({ name, href }) => (
                    <Breadcrumb.Item href={href} key={name} as={"li"}>
                      {name}
                    </Breadcrumb.Item>
                  ))}
                  <Breadcrumb.Item as={"li"} active>
                    {title}
                  </Breadcrumb.Item>
                </Breadcrumb>
              </div>
            )}
            <h4 className="page-title">
              {title}
            </h4>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default PageBreadcrumb;
