import { DropDown, PageBreadcrumb } from "@/components";
import ListTask from "@/pagesExample/apps/tasks/List";
import { removeMembership, useUpdateUserModal, InviteModal } from "@/pages_old/Members";
import { graphql } from "babel-plugin-relay/macro";
import { compact, find, map } from "lodash";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useLazyLoadQuery } from "react-relay";

import { Filters, Search } from "../Table";

import {
  ClubUserFilter as UsersFilter,
  UsersPageQuery,
} from "./__generated__/UsersPageQuery.graphql";

const { loadStore } = require("../../redux/store/localStorage");

const getCurrentClub = () => {
  try {
    return loadStore().currentClub || {};
  } catch (err) {
    return {};
  }
};

const filters: (UsersFilter | null)[] = [null, "admin"];

const query = graphql`
  query UsersPageQuery($id: ID, $filter: ClubUserFilter, $search: String) {
    club(id: $id) {
      members(filter: $filter, search: $search) {
        ...Members_MembersConnection
        __id
        edges {
          id
          kind
          node {
            id
            email
            name
          }
        }
      }
    }
  }
`;

export default function UsersPageLoader() {
  const { currentClub } = getCurrentClub();
  const clubId = currentClub?.id;
  const roleId = currentClub?.roleId;

  const [filter, setFilter] = useState<UsersFilter | null>(null);
  const [search, setSearch] = useState<string | null>(null);

  const { club } = useLazyLoadQuery<UsersPageQuery>(query, {
    id: clubId,
    search,
    filter,
  });

  if (!club) return <></>;
  const { t } = useTranslation();

  const {
    members: { __id: connectionId, edges = [] },
  } = club;

  const users = compact(
    map(edges, (edge) => {
      if (edge && edge.node) {
        const {
          id,
          node: { name, email },
        } = edge;

        return {
          id,
          title: `${name}(${email})`,
          subtasks: t(`roles.${edge.kind}`)
        };
      }
    }),
  );

  const {
    setId,
    setRole,
    Modal,
    onClick: toggleShow,
  } = useUpdateUserModal({ id: "", role: "general" });

  const showModalForUser = (id: string) => {
    const edge = find(edges, (e) => e?.id === id);
    if (edge && edge.kind) {
      setRole(edge.kind);
    }
    setId(id);
    toggleShow();
  };

  const removeMembershipByEdgeId = (edgeId: string) => {
    const edge = find(edges, (e) => e?.id === edgeId);
    if (edge?.node?.id) {
      removeMembership(edge.node.id, clubId, connectionId);
    }
  };

  return (
    <>
      <div className="page-title-box">
        <PageBreadcrumb title={
          <>
            Members
            <InviteModal
              connectionId={club.members.__id}
              clubId={clubId}
              className="ms-3"
            />
          </> 
        }>
          <div className="page-title-right">
            <div className='input-group'>
              <Search setSearch={setSearch} />
              <Filters {...{ setFilter, filter, filters }} />
            </div>
          </div>
        </PageBreadcrumb>
      </div>

      <Modal />
      <ListTask
        sections={[{ id: "users", tasks: users }]}
        collapsable={false}
        ellipse={false}
        CentralColumn={({ task }) => (<>{task.subtasks || ''}</>)}
      >
        {(item) => {
          if (item.id === roleId) {
            return <></>;
          }
          const dropdowns = [
            {
              label: "Edit",
              className: "mdi-pencil",
              onClick: () => showModalForUser(item.id),
            },
            {
              label: "Delete",
              hasDivider: true,
              className: "mdi-window-close text-danger",
              onClick: () => removeMembershipByEdgeId(item.id),
            },
          ];
          return (
            <li className="list-inline-item ms-2">
              <DropDown menuItems={dropdowns}>
                <i className="mdi mdi-dots-vertical"></i>
              </DropDown>
            </li>
          );
        }}
      </ListTask>
    </>
  );
}
