import { PageBreadcrumb } from "@/components";
import { updateTask } from "@/pages_old/Tasks";
import CreateTaskModal from "@/pages_old/Tasks/CreateTaskModal";
import { permissions } from "@/services/permissions";
import { graphql } from "babel-plugin-relay/macro";
import { compact, map } from "lodash";
import Moment from "moment";
import { useState } from "react";
import { useLazyLoadQuery } from "react-relay";

import { Filters, Search } from "../Table";
import { useTaskOrdering } from "../Tasks";

import ListTask, { type ListTaskItem } from "@/pagesExample/apps/tasks/List";
import {
  TasksFilter,
  TasksPageQuery,
} from "./__generated__/TasksPageQuery.graphql";

const { loadStore } = require("../../redux/store/localStorage");

const getCurrentClub = () => {
  try {
    return loadStore().currentClub || {};
  } catch (err) {
    return {};
  }
};

const filters: (TasksFilter | null)[] = [
  null,
  "unassigned",
  "mine",
  "overdue",
  "incompleted",
];

const query = graphql`
  query TasksPageQuery(
    $id: ID
    $filter: TasksFilter
    $search: String
    $order: [OrderType!]
  ) {
    club(id: $id) {
      tasks(filter: $filter, search: $search, order: $order) {
        __id
        edges {
          node {
            id
            assignees {
              edges {
                node {
                  id
                }
              }
              ...Avatars_users
              ...Assignment_Assignees
            }
            dueDate
            complete
            description
            parent {
              ... on TaskableInterface {
                id
                name
                __typename
              }
            }
            comments {
              totalCount
            }
          }
        }
      }
    }
  }
`;

const updateDate = (date: Date | null, { id }: ListTaskItem) => {
  if (date) {
    updateTask({ id, dueDate: Moment(date).toISOString() });
  }
};

export default function TasksPageLoader() {
  const { currentClub } = getCurrentClub();
  const clubId = currentClub?.id;

  const {
    task: { create: taskCreate },
  } = permissions();

  const [filter, setFilter] = useState<TasksFilter | null>("incompleted");
  const [search, setSearch] = useState<string | null>(null);
  const { order } = useTaskOrdering();

  const { club } = useLazyLoadQuery<TasksPageQuery>(query, {
    id: clubId,
    search,
    filter,
    order,
  });

  if (!club || !club.tasks) return <></>;

  const tasks = compact(
    map(club.tasks.edges || [], (edge) => {
      if (edge && edge.node) {
        const {
          id,
          description: title,
          comments,
          dueDate,
          assignees: users,
          complete,
          parent,
        } = edge.node;

        return {
          id,
          title,
          link: `/tasks/${id}`,
          comments,
          complete,
          dueDate,
          users,
          owner: parent,
        };
      }
    }),
  );

  const onCheck = ({ id, complete }: ListTaskItem) => updateTask({ id, complete: !complete })

  return (
    <>
      <div className="page-title-box">
        <PageBreadcrumb title={
          <>
            Tasks
            {taskCreate && (
              <CreateTaskModal
                connectionId={club.tasks.__id}
                className="ms-3"
                taskable={{ id: clubId, type: "club" }}
              />
            )}
          </>
        }>
          <div className="page-title-right">
            <div className='input-group'>
              <Search setSearch={setSearch} />
              <Filters {...{ setFilter, filter, filters }} />
            </div>
          </div>
        </PageBreadcrumb>
      </div>

      <ListTask
        showParent
        onCheck={onCheck}
        sections={[{ id: "tasks", tasks }]}
        collapsable={false}
        type="task"
        onUpdateDate={updateDate}
      />
    </>
  );
}
