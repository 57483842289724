import updateTask from "@/pages_old/Tasks/UpdateTask";
import { graphql } from "babel-plugin-relay/macro";
import { Row, Col, Card } from 'react-bootstrap';
import { Link } from "react-router-dom";
import { compact, map } from "lodash";
import Moment from "moment";
import { useFragment } from "react-relay";
import TaskSection from "../List/Section";
import { useCreateTask, type Taskables } from "@/pages_old/Tasks/CreateTaskModal";

import { type Checklist_TaskConnection$key as ConnectionTypeKey } from "./__generated__/Checklist_TaskConnection.graphql";

const fragment = graphql`
  fragment Checklist_TaskConnection on TaskConnection {
    __id
    edges {
      node {
        id
        users: assignees {
          edges {
            node {
              id
            }
          }
          ...Avatars_users
          ...Assignment_Assignees
        }
        dueDate
        complete
        title: description
        parent {
          ... on TaskableInterface {
            id
            name
            __typename
          }
        }
        comments {
          totalCount
        }
      }
    }
  }
`;

interface Taskable {
  id: string;
  type: Taskables;
};

interface PropsType {
  taskable?: Taskable,
  tasks: ConnectionTypeKey;
  short?: boolean;
}

interface NewTaskProps {
  taskable?: Taskable;
  connectionId: string;
}

const NewTask = ({
  taskable,
  connectionId
}: NewTaskProps) => {
  if(!taskable) { return <></> }

  const { toggleShow: toggleTaskShow, Modal: NewTaskModal } = useCreateTask({
    taskable,
    connectionId,
  });

  return(
    <NewTaskModal>
      <Card className="mb-0">
        <Card.Body className="py-1">
          <Row className="justify-content-sm-between my-1">
            <Col className="mb-2 mb-sm-0">
              <div className="border-dashed border-2 border h-100 w-100 rounded d-flex align-items-center justify-content-center">
                <Link
                  to=""
                  onClick={toggleTaskShow}
                  className="text-center text-muted p-2 d-flex"
                >
                  <i className="mdi mdi-plus h3 my-0" style={{ height: 'inherit' }}></i>
                  <div className="font-16 mb-0 d-block">Add Task</div>
                </Link>
              </div>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </NewTaskModal>
  )
}

const Checklist = ({ short = false, taskable, ...props }: PropsType) => {
  const conn = useFragment<ConnectionTypeKey>(fragment, props.tasks);
  var tasks = compact(map(conn?.edges || [], (edge) => edge?.node));
  tasks = map(tasks, (t) => ({ link: `/tasks/${t.id}`, ...t }));

  if(tasks.length === 0) {
    return(
      <NewTask taskable={ taskable } connectionId={conn?.__id || ''} />
    );
  }

  return (
    <>
      <TaskSection
        taskable={taskable}
        title="Checklists/Sub-tasks"
        tasks={tasks}
        type="task"
        short={short}
        onCheck={({ id, complete }) => updateTask({ id, complete: !complete })}
        onDateUpdate={(newDate, task) => {
          updateTask({
            id: task.id,
            dueDate: Moment(newDate).toISOString(),
          });
        }}
      />
      <NewTask taskable={ taskable } connectionId={conn?.__id || ''} />
    </>
  );

};

export default Checklist;
